<!-- 学员新增 -->
<template>
    <div class="AddTrainee">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">学员新增</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单 -->
        <div class="" style="width: 100%;display: flex;justify-content: space-around;">
            <div class="" style="width: 85%;">
                <el-form ref="form" :model="form" :rules="rules" id="form-1" class="form" inline label-width="150px">
                    <el-form-item label="学员编号">
                        <el-input v-model="form.studentNum" placeholder=""></el-input>
                    </el-form-item>
                    <el-form-item label="学员姓名" prop="studentName">
                        <el-input v-model="form.studentName" placeholder="请输入学员姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="移动电话" prop="mobilePhone">
                        <el-input v-model="form.mobilePhone" placeholder="请输入移动电话"></el-input>
                    </el-form-item>
                    <el-form-item label="助记码">
                        <el-input v-model="form.mnemonicCode" placeholder="请输入助记码"></el-input>
                    </el-form-item>
                    <el-form-item label="学生卡号" prop="studentCard">
                        <el-input v-model="form.studentCard" placeholder="请输入学生卡号"></el-input>
                    </el-form-item>
                    <el-form-item label="拼音名">
                        <el-input v-model="form.pinyin" placeholder="请输入拼音名"></el-input>
                    </el-form-item>
                    <el-form-item label="证件号码" prop="certificate">
                        <el-input v-model="form.certificate" placeholder="请输入证件号码"></el-input>
                    </el-form-item>
                    <el-form-item label="入学年份" prop="entrance">
                        <el-date-picker
                              v-model="form.entrance"
                              type="date"
                              placeholder="选择日期">
                            </el-date-picker>
                    </el-form-item>
                    <el-form-item label="现年级" prop="currentGrade">
                        <el-input v-model="form.currentGrade" placeholder="请输入现年级"></el-input>
                    </el-form-item>
                    <el-form-item label="出生日期" prop="birthday">
                        <el-date-picker
                              v-model="form.birthday"
                              type="date"
                              placeholder="选择日期">
                            </el-date-picker>
                    </el-form-item>
                    <el-form-item label="就读学校" prop="school">
                        <el-input v-model="form.school" placeholder="请输入就读学校"></el-input>
                    </el-form-item>
                    <el-form-item label="民族" prop="nation">
                        <el-input v-model="form.nation" placeholder="请输入民族"></el-input>
                    </el-form-item>
                    <el-form-item label="籍贯">
                        <el-input v-model="form.nativePlace" placeholder="请输入籍贯"></el-input>
                    </el-form-item>
                    <el-form-item label="性别" prop="sex">
                        <el-input v-model="form.sex" placeholder="请输入性别"></el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select v-model="form.condition" placeholder="请选择状态" :clearable="true">
                            <el-option
                              v-for="item in form.conditionOpt"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                    </el-form-item>
                    <el-form-item label="类型">
                        <el-select v-model="form.type" placeholder="请选择状态" :clearable="true">
                            <el-option
                              v-for="item in form.typeOpt"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                    </el-form-item>
                    <el-form-item label="家长姓名" prop="parentName">
                        <el-input v-model="form.parentName" placeholder="请输入家长姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="家长密码" prop="parentPwd">
                        <el-input v-model="form.parentPwd" placeholder="请输入家长密码"></el-input>
                    </el-form-item>
                    <el-form-item label="家庭电话" prop="homePhone">
                        <el-input v-model="form.homePhone" placeholder="请输入家庭电话"></el-input>
                    </el-form-item>
                    <el-form-item label="账号有效期" prop="effective">
                        <el-date-picker
                              v-model="form.effective"
                              type="date"
                              placeholder="选择日期">
                            </el-date-picker>
                    </el-form-item>
                    <el-form-item label="联系邮件" prop="mail">
                        <el-input v-model="form.mail" placeholder="请输入联系邮件"></el-input>
                    </el-form-item>
                    <el-form-item label="QQ/微信">
                        <el-input v-model="form.QQ_WeChat" placeholder="请输入QQ/微信"></el-input>
                    </el-form-item>
                    <el-form-item label="爱好">
                        <el-input v-model="form.hobby" placeholder="请输入爱好"></el-input>
                    </el-form-item>
                    <el-form-item label="健康状态">
                        <el-input v-model="form.healthy" placeholder="请输入健康状态"></el-input>
                    </el-form-item>
                    <el-form-item label="邮政编码" >
                        <el-input v-model="form.zipCode" placeholder="请输入邮政编码"></el-input>
                    </el-form-item>
                    <el-form-item label="地址" >
                        <el-input  class="text-ipt" v-model="form.address" placeholder="请输入地址"></el-input>
                    </el-form-item>
                    <el-form-item label="登录账号" prop="account">
                        <el-input v-model="form.account" placeholder="请输入登录账号"></el-input>
                    </el-form-item>
                    <el-form-item label="学生密码" prop="studentPwd">
                        <el-input v-model="form.studentPwd" placeholder="请输入学生密码"></el-input>
                    </el-form-item>
                    <el-form-item label="其他资料">
                        <el-input v-model="form.explain" type="textarea" class="text-ipt" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="" style="width:15%;margin-right:50px;">
                <span style="color: #666;margin-bottom: 7px;">照片</span>
                <el-upload class="upload-demo" 
                    drag :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
                    action="https://jsonplaceholder.typicode.com/posts/">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                    <i class="el-icon-upload" v-else></i>              
                    <div class="el-upload__text" v-if="imageUrl==''">将文件拖到此处，或
                        <span style="color: red;">
                            点击上传
                        </span>
                    </div>
                    <div class="el-upload__tip" v-if="imageUrl==''">只能上传jpg/png文件，且不超过5M</div>
                </el-upload>
            </div>
        </div>
        <el-divider></el-divider>
        <!-- 底部 提交与取消 -->
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    studentNum:"",//学员编号
                    studentName:"",//学员姓名
                    mobilePhone:"",//移动电话
                    mnemonicCode:"",//助记码
                    studentCard:"",//学生卡号
                    pinyin:"",//拼音名
                    certificate:"",//证件号码
                    entrance:"",//入学年份
                    currentGrade:"",//现年级
                    birthday:"",//出生日期
                    school:"",//就读学校
                    nation:"",//民族
                    nativePlace:"",//籍贯
                    sex:"",//性别
                    condition:"1",//状态
                    conditionOpt:[
                        {label: "未入学", value: "1"},
                        {label: "学员", value: "2"},
                        {label: "试学", value: "3"},
                    ],//状态选项
                    type:"1",//类型
                    typeOpt:[
                        {label: "培训", value: "1"},
                        {label: "考试", value: "2"},
                        {label: "两者", value: "3"},
                    ],//类型选项
                    parentName:"",//家长姓名
                    parentPwd:"",//家长密码
                    homePhone:"",//家庭电话
                    effective:"",//账号有效期
                    mail:"",//联系邮件
                    QQ_WeChat:"",//QQ/微信
                    hobby:"",//爱好
                    healthy:"",//健康状态
                    zipCode:"",//邮政编码
                    address:"",//地址
                    account:"",//登录账号
                    studentPwd:"",//学生密码
                    explain:""//其他资料
                },
                imageUrl:"",//上传的图片
                rules: {
                    studentName:[
                         { required: true, message: '学员姓名不能为空', trigger: 'blur' },
                    ],
                    mobilePhone:[
                         { required: true, message: '移动电话不能为空', trigger: 'blur' },
                    ],
                    studentCard:[
                         { required: true, message: '学生卡号不能为空', trigger: 'blur' },
                    ],
                    certificate:[
                         { required: true, message: '证件号码不能为空', trigger: 'blur' },
                    ],
                    entrance:[
                         { type: 'date', required: true, message: '入学年份不能为空', trigger: 'change' }
                    ],
                    currentGrade:[
                         { required: true, message: '现年级不能为空', trigger: 'blur' },
                    ],
                    birthday:[
                         { type: 'date', required: true, message: '出生日期不能为空', trigger: 'change' }
                    ],
                    school:[
                         { required: true, message: '就读学校不能为空', trigger: 'blur' },
                    ],
                    nation:[
                         { required: true, message: '民族不能为空', trigger: 'blur' },
                    ],
                    sex:[
                         { required: true, message: '性别不能为空', trigger: 'blur' },
                    ],
                    parentName:[
                         { required: true, message: '家长姓名不能为空', trigger: 'blur' },
                    ],
                    parentPwd:[
                         { required: true, message: '家长密码不能为空', trigger: 'blur' },
                    ],
                    homePhone:[
                         { required: true, message: '家庭电话不能为空', trigger: 'blur' },
                    ],
                    effective:[
                         { type: 'date', required: true, message: '账号有效期不能为空', trigger: 'change' }
                    ],
                    account:[
                         { required: true, message: '登录账号不能为空', trigger: 'blur' },
                    ],
                    studentPwd:[
                         { required: true, message: '学生密码不能为空', trigger: 'blur' },
                    ]
                }
            }
        },
        created() {

        },
        methods: {
            goBack() { //返回
                this.$router.go(-1);
            },
            submitForm(form) { //提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        console.log(this.form)
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) { //取消
                this.$router.push({
                    path: "trainee"
                })
                this.$refs[form].resetFields();
            },
            // 上传图片
            handleAvatarSuccess(res, file) {
                console.log(res)
                // this.imageUrl = URL.createObjectURL(file.raw);
                   
                
            },
            beforeAvatarUpload(file) {
                // const isJPG = file.type === 'image/jpg';
                // const isPNG = file.type === 'image/png';
                // const isLt5M = file.size / 1024 / 1024 < 5;
            
                // if (!isJPG) {
                //     this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
                // }
                // if (!isLt5M) {
                //     this.$message.error('上传头像图片大小不能超过 5MB!');
                // }
                // return isJPG && isLt5M;
            }
        }
    }
</script>

<style scoped="scoped">
    .AddTrainee {
        width: 100%;
        height: 100%;
    }

    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }

    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }

   /* 表单部分 */
   .el-form {
       display: flex;
       justify-content: start;
       flex-wrap: wrap;
   }
   
   
   .form ::v-deep .el-form-item__label {
       color: #000000;
   }
   
   .el-input {
       width: 300px;
       height: 37px !important;
   }
   
   .el-select {
       width: 300px;
   }
    /* 上传图片 */
    ::v-deep .el-upload-dragger {
        width: 173px;
        height: 211px;
        position: relative;
    }
    .el-upload-dragger .el-icon-upload{
        margin-top: 25px;
    }
    ::v-deep .el-upload__text,
    .el-upload__tip {
        font-size: 12px;
        width: 108px;
        margin: 8px auto;
    }
    .avatar{
        width: 173px;
        height: 211px;
    }
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }
    
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
